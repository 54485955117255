<template>
  <v-container class="d-flex justify-center">
    <v-card min-width="400px">
      <v-card-title>Testpersonen importieren</v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="step == 1">
        <v-file-input v-model="file" chips show-size label="File input"></v-file-input>
        <v-btn color="primary" :disabled="!file" :loading="loading" class="float-right mb-3" @click="uploadWorker"
          >Einlesen</v-btn
        >
      </v-card-text>
      <v-card-text v-if="step == 2">
        <p>Bitte ordnen Sie die entsprechenden Spaltennamen zu.</p>
        <div class="d-inline-flex">
          <v-select v-model="csvheaders.name" class="mr-4" :items="Object.keys(worker[0])" label="Vorname"></v-select>
          <v-select v-model="csvheaders.lastName" :items="Object.keys(worker[0])" label="Nachname"></v-select>
        </div>
        <v-select v-model="csvheaders.gender" :items="Object.keys(worker[0])" label="Gender" required></v-select>
        <v-select v-model="csvheaders.email" :items="Object.keys(worker[0])" label="E-Mail Adresse" required></v-select>
        <v-select v-model="csvheaders.streetName" :items="Object.keys(worker[0])" label="Straße" required></v-select>
        <v-select
          v-model="csvheaders.houseNumber"
          :items="Object.keys(worker[0])"
          label="Hausnummer"
          required
        ></v-select>
        <v-select v-model="csvheaders.zipCode" :items="Object.keys(worker[0])" label="Postleitzahl" required></v-select>
        <v-select v-model="csvheaders.cityName" :items="Object.keys(worker[0])" label="Stadt" required></v-select>
        <v-select
          v-model="csvheaders.phoneNumber"
          :items="Object.keys(worker[0])"
          label="Telefonnummer"
          required
        ></v-select>
        <v-select
          v-model="csvheaders.birthday"
          :items="Object.keys(worker[0])"
          label="Geburtsdatum"
          required
        ></v-select>
        <v-btn color="primary" :loading="loading" class="float-right mb-3" @click="showWorker">Auswerten</v-btn>
      </v-card-text>
      <v-card-text v-if="step == 3">
        <p>
          Bitte überprüfen Sie die eingebenen Daten auf leere Felder und löschen sie gegebene Leerzeilen aus Ihrer
          CSV-Datei.
        </p>
        <v-data-table :headers="headers" :items="worker"> </v-data-table>
        <v-btn color="primary" :loading="loading" class="float-right mb-3" @click="saveWorker">Speichern</v-btn>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      step: 1,
      worker: null,
      csvheaders: {},
      headers: [],
      file: null,
      loading: false,
      snackbar: false,
      snackbarText: "",
    };
  },
  methods: {
    uploadWorker() {
      var workerList = [];
      this.loading = true;

      this.$papa.parse(this.file, {
        header: true,
        worker: true,
        step: function(row) {
          workerList.push(row.data);
        },
        complete: () => {
          this.worker = workerList;
          this.loading = false;
          this.step = 2;
        },
      });
    },

    showWorker() {
      this.step = 3;
      this.headers = [
        { text: "Vorname", value: this.csvheaders.name, sortable: false },
        { text: "Nachname", value: this.csvheaders.lastName, sortable: false },
        { text: "Gender", value: this.csvheaders.gender, sortable: false },
        { text: "E-Mail Adresse", value: this.csvheaders.email, sortable: false },
        { text: "Straße", value: this.csvheaders.streetName, sortable: false },
        { text: "Hausnummer", value: this.csvheaders.houseNumber, sortable: false },
        { text: "Postleitzahl", value: this.csvheaders.zipCode, sortable: false },
        { text: "Stadt", value: this.csvheaders.cityName, sortable: false },
        { text: "Telefonnummer", value: this.csvheaders.phoneNumber, sortable: false },
        { text: "Geburtsdatum", value: this.csvheaders.birthday, sortable: false },
      ];
    },

    saveWorker() {
      this.$http({
        method: "post",
        url: "worker/create-multiple-worker",
        data: {
          gender: this.csvheaders.gender,
          name: this.csvheaders.name,
          lastName: this.csvheaders.lastName,
          email: this.csvheaders.email,
          street: this.csvheaders.streetName,
          city: this.csvheaders.cityName,
          zip: this.csvheaders.zipCode,
          birthday: this.csvheaders.birthday,
          phone: this.csvheaders.phoneNumber,
          number: this.csvheaders.houseNumber,
          workerList: this.worker,
          org: this.$route.params.id,
        },
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch((err) => {
          this.snackbarText = "Fehler bei Abfrage";
          this.snackbar = true;
          console.log("AXIOS ERROR: ", err);
        });
    },
  },
};
</script>
